<template>
    <div class="record">
        <van-sticky>
            <van-nav-bar title="History" left-arrow @click-left="onClickLeft" class="retreat">
            </van-nav-bar>
        </van-sticky>
        <div class="content">
            <div class="box" v-for="item in info" :key="item.reply_at">
                <div class="time">{{ item.reply_at }}</div>
                <div class="text">
                  <div class="text-img" style="float: right">
                    <van-image
                        :src="require('../../assets/ic_avatar_default.png')"
                    />
                  </div>
                  <div class="text-text" style="float: right">
                      <div>{{ item.content }}</div>
                      <div class="left-image"><img src="../../assets/ic_feedback_right.png" alt=""></div>
                  </div>
                  <div style="clear: both"> </div>
                </div>
              <div class="text reply" v-show="item.reply_content">
                <div class="text-img" style="float: left">
                  <van-image
                      :src="require('../../assets/ic_avatar_default.png')"
                  />
                </div>
                <div class="text-text reply-text" style="float: left">
                  <div class="right-image"><img src="../../assets/ic_feedback_right.png" alt=""></div>
                  <div>{{ item.reply_content }}</div>
                </div>
                <div style="clear: both"></div>
              </div>
            </div>
        </div>
        <div class="navs" v-if="isok" @click="toOpiniao">
            <img src="../../assets/ic_feedback_edit.png" alt="">
        </div>
    </div>
</template>
<script>
import { getfkInfo } from '@/api/Mue/index'

export default {
    name: '',
    components: {},
    data() {
        return {
            isok: true, //控制图标的
            info: [], //反馈的信息
        }
    },
    // 计算属性
    computed: {},
    watch: {},
    mounted() {
        this.getFKinfo()
        this.kong()
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        // 点击图标去往填写反馈信息的页面
        toOpiniao() {
            this.$router.push('/feedback')
        },
        getFKinfo() {
            getfkInfo({ pn: 1 }).then(res => {
                let data = res.result
                data.reverse();

                data.forEach((item) => {
                  let content = item.content
                  let tag = item.tags

                  item.content = content.replace('(' + tag + ')', '')
                });

                this.info = data
                console.log(this.info)
            })
        },
        // 控制返回图标
        kong() {
            if (this.info.length === 0) {
                return
            }else{
                this.isok = !this.isok
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.record {
    min-height: 100vh;
    background-color: var(--title-color);
}

/deep/.van-nav-bar {
    background-color: var(--main-color);
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: var(--title-color);
}

/deep/.van-nav-bar .van-icon {
    opacity: 0.8;
    font-size: 18px;
    color: var(--title-color);
}

/deep/.van-nav-bar__title {
    opacity: 0.8;
    font-size: 18px;
    color: var(--title-color);
}

.box {
    padding: 10px;
}

.time {
    text-align: center;
    font-size: 12px;
    margin-bottom: 5px;
}

.text {
    //display: flex;
    //justify-content: end;
}

.navs {
    position: fixed;
    bottom: 5vh;
    right: 5vw;
}

.text-text {
    max-width: 60vw;
    font-family: Alibaba Sans;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 22px;
    letter-spacing: 0px;
    color: #333333;
    opacity: 0.8;
    padding: 15px 10px;
    background-color: #FBEAED;
    margin-right: 15px;
    position: relative;
    border-radius: 4px;
    word-wrap: break-word;
    float: right;
}

.left-image {
    position: absolute;
    top: 6px;
    right: -9px;
}
/deep/.van-image__img{
  width: 40px;
}
.reply{
  margin-top: 10px;
  //justify-content: left;
}
.reply-text{
  margin-left: 15px;
}
.reply-text div:first-child {
  position: absolute;
  top: 6px;
  left: -9px;
}
.right-image {
  position: absolute;
  top: 6px;
  left: -9px;
  transform: rotate(180deg);
}
</style>
